$body-bg: #171717;
$body-color: #eeeeee;

#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $body-color;
  text-align: center;
}

$theme-colors: (
  "primary": #21BECE
);

label {
  color: #ccc !important;
  line-height: .5 !important;
  font-size: small !important;
}

h1,h2,h3,h4,h5,h6 {
  color: $body-color;
}

button {
  height: 45px;
}

.signup-buttons {
  button {
    margin: 0 !important;
    width: 100% !important;
  }
}

input, select {
  height: 45px !important;
  border-radius: 3px !important;
  border: 1px solid rgba(255, 255, 255, 0.38) !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
  color: #ffffff !important;
  font-size: 14px !important;
  letter-spacing: 0.25px;
}
input:focus, select:focus, .recurly-hosted-field-focus  {
  outline: none;
  border: 1px solid #21BECE !important;
  box-shadow: none !important;
}
.recurly-hosted-field {
  height: 45px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.38);
  border-radius: 3px;
  font-size: 14px;
}

.input-group {
  position: relative !important;
}
.signup-order-summary-section, #agreement-clause {
  padding: 0 !important;
  background-color: $body-bg !important;
}

.signup-order-summary-item-title {
  font-size: 15px;
}

.signup-order-summary-item-price {
  font-size: 17px;
}

.total {
  background-color: #171717 !important;
  padding: 20px 0 !important;
  font-size: larger;
  font-weight: bolder;
  border-bottom: 0 !important;
}

.form-input-password-show-toggle {
  color: #eeeeee;
}

.signup-shipping-info {
  span {
    display: inline-block !important;
    width: auto;
  }
  a {
    float: right !important;
  }
}

#signup-form-errors {
  top: 0;
  margin: 0;
  border-color: #ea203f;
  background-color: #212121;
  p {
    margin-bottom: 0 !important;
  }
}

.signup-summary-info {
  > div {
    float: left;
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 0 !important;
    line-height: 1.25;
  }
  .signup-summary-info-button {
    clear: none;
    float: right;
  }
  button {
    height: 0px;
    padding: 0px;
  }
}
.form-section-title {
  // margin-bottom: 0 !important;
  font-size: medium;
  font-weight: bold;
}

.tip > div {
  display: inline;
}

.w-50 {
  width: 49% !important;
}

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  140 !default;
