.aira-btn {
  background: -webkit-linear-gradient(left, $aira-color-gradient-left, $aira-color-gradient-right);
  background: -o-linear-gradient(left, $aira-color-gradient-left, $aira-color-gradient-right);
  background: -webkit-gradient(linear, left top, right top, from($aira-color-gradient-left), to($aira-color-gradient-right));
  background: linear-gradient(to right, $aira-color-gradient-left, $aira-color-gradient-right);
  width: 140px;
  margin: 5px 10px;
  padding: 13px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid $aira-color-notice-border;
  font-weight: 500;
  font-size: 17px;
  overflow: hidden;
  color: $aira-color-button-text;
  position: relative;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  &:disabled {
    opacity: 0.60;
  }
}

.aira-btn-secondary {
  height: 45px;
  border: 1px solid orange;
  background-color: white;
  border-radius: 4px;
  margin-left: 10px;
}

.aira-action-link {
  color: $aira-color-primary;
  // text-decoration: underline;
}

// .signup-buttons {
//   margin-top: 25px;
// }

.buttons-select-option-button {
  position: relative;
  display: block;
  height: 90px;
  width: 100%;
  margin-bottom: 15px !important;
  padding: 0 !important;
  color: #eeeeee !important;
  span {
    display: block;
  }
}
.buttons-select-option-link {
  text-align: center;
  width: 100%;
}
.buttons-select-option-button:hover  {
  color: #eeeeee !important;
  .buttons-select-option-content-title{
    background-color: #eeeeee;
    color: $aira-color-primary;
  }
}
.buttons-select-option-content-title {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $aira-color-primary;
  color: #171717;
  width: 100px;
  border-bottom-right-radius: 5px;
  font-size: 15px;
  padding: 2px;
}
.buttons-select-option-content-main {
  font-size: larger;
  padding: 20px 0 0 25px;
}
.buttons-select-option-content-left {
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px 0 0 25px;
  span {
    text-align: left;
    display: block;
    float: left;
  }
}
.buttons-select-option-content-right {
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px 25px 0 0;
}
.buttons-select-option-content-left-main {
  text-align: left;
  line-height: 1;
}
.buttons-select-option-content-right-main {
  text-align: right;
  line-height: 1;
}
.buttons-select-option-content-right-main-only {
  text-align: right;
  line-height: 1;
  padding-top: 10px;
  font-size: larger;
}
.buttons-select-option-content-left-sub,.buttons-select-option-content-right-sub {
  font-size: small;
  line-height: 1;
  clear: both !important;
}