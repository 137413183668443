footer {
  font-size: 12px;
}
a {
  font-weight: bold;
  color: $aira-text-color-link;
}
#app {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $aira-text-color;
  text-align: center;
}

.profile-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 28px;
  outline: 0 !important;
  padding-top: 30px;
  padding-bottom: 20px;
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

.profile-description {
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  font-weight: normal;
  margin: 0 !important;
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

.profile-heading {
  font-weight: normal;
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 16px;
  margin: 0 !important;
  padding-bottom: 10px;
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

.profile-subtitle {
  display: inline-block;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.15px;
  margin: 0 !important;
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

.profile-body {
  display: block;
  text-align: left;
  font-size: 14px;
  opacity: 0.60;
  letter-spacing: 0.25px;
  margin: 0 !important;
}

.profile-body-title {
  @extend .profile-title;
  padding-top: 25px;
}

.profile-edit-link {
  float: right;
  text-align: right;
  font-size: 13px !important;
  letter-spacing: 1.25px;
  line-height: 16px;
  color: #21BECE;
  font-weight: 500 !important;
  padding: 0px 5px !important;
  height: 25px;
  z-index: 5;
  i {
    font-size: 18px;
  }
}

.profile-add-link {
  height: 15px;
}

.profile-button {
  font-size: 14px !important;
  letter-spacing: 1.25px;
  line-height: 16px !important;
  font-weight: bold !important;
  height: 40px;
}