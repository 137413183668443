$aira-color-primary: #21BECE;

// bg colors
$aira-bg-color-container: #f2f2f2;
$aira-bg-color-content-container: #ffffff;

// text colors
$aira-text-color: #4A4A4A;
$aira-text-color-title: #2A2A2A;
$aira-text-color-link: #2A2A2A;

// gradients
$aira-color-gradient-left: #00D9B3;
$aira-color-gradient-right: #20B8C5;

// notice
$aira-color-notice-bg: #ebfafa;
$aira-color-notice-border: #66dad6;
$aira-color-notice-border-secondary: #cbf2f1;

// warning
$aira-color-warning-bg: #FDF6ED;
$aira-color-warning-border: #F0AD4E;

// errors
$aira-color-errors-bg: #fcebee;
$aira-color-errors-border: #f28290;

// object colors
$aira-color-divider: #d9d9d9;
$aira-color-button-text: #2A2A2A;
$aira-color-border: #2A2A2A;

@import '../styles/styles.scss';
#external-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  .layout-container {
    flex-grow: 1;
    background-color: $aira-bg-color-container;
  }
}
