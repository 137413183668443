// global layouts

#app, #default-layout, #blank-layout, #basic-layout, #external-layout {
  height: 100%;
}

.container-fluid {
  padding: 0 !important;
}

.form-container {
  margin: 50px 5px;
  background-color: $aira-bg-color-content-container;
  padding-bottom: 15px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  @media screen and (min-width: $aira-min-width + 10px) {
    width: $aira-min-width;
    margin: 50px auto;
  }
}

.form {
  height: auto;
  width: 100%;
}

.aira-container {
  margin: 50px 5px;
  padding-bottom: 15px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  @media screen and (min-width: $aira-min-width + 10px) {
    width: $aira-min-width;
    margin: 50px auto;
  }
}