@import './../variables/_colors.scss';
@import './../variables/_sizes.scss';

.form-group-tip {
  position: relative;
  float: right;
  right: 0;
  top: -11px;
  height: 0px;
  margin: 0;
  opacity: 0.5;
}

.form-inline-input {
  display: inline-block;
  width: 74%;
  & input {
    width: 100%;
    display: inline-block;
    margin: 0;
  }
}
.form-inline-button {
  display: inline-block;
  width: 24%;
  float: right;
  height: 45px;
}

.invalid {
  border: 1px solid #E71D36 !important;
}

.form-control.is-invalid, .form-control.is-valid {
  background-position: right calc(.375em + .1875rem) center !important;
}

.invalid-feedback {
  text-align: left;
  color: #E71D36;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

.aira-input {
  height: 45px;
  width: 100%;
  padding: 0 0 0 15px;
  margin: 0;
  border: 1px solid $aira-color-border;
  border-radius: 3px;
  text-align: left;
}

.form-section-title {
  font-size: larger;
  font-weight: bold;
}

.form-section-description {
  font-size: 14px;
  margin-bottom: 0;
}

.recurly-element-card {
  height: 45px;
  border: 1px solid $aira-color-border;
  padding: 0 5px;
  border-radius: 3px;
}

.recurly-element-focus {
  outline: none;
  border: 1px solid #21BECE !important;
  box-shadow: none !important;
}

.recurly-hosted-field {
  width: 100%;
}

.w-100 {
  width: 100% !important;
}

.w-50-100 {
  width: 100% !important;
  @media (min-width: $aira-min-width) {
    width: 49% !important;
  }
}
.w-75 {
  width: 74% !important;
}

.w-25 {
  width: 24% !important;
}
.w-30-100 {
  width: 100% !important;
  @media (min-width: $aira-min-width) {
    width: 32% !important;
  }
}
.w-30-50 {
  width: 49% !important;
  @media (min-width: $aira-min-width) {
    width: 30% !important;
  }
}
.w-25-35 {
  width: 34% !important;
  @media (min-width: $aira-min-width) {
    width: 24% !important;
  }
}
.w-75-65 {
  width: 64% !important;
  @media (min-width: $aira-min-width) {
    width: 74% !important;
  }
}