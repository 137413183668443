$body-bg: #FFFFFF;
$body-color: #2A2A2A;

#app {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $body-color;
  text-align: center;
}

$theme-colors: (
  "primary": $aira-color-primary,
  "secondary": #f0ad4e
);

p {
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  color: $body-color;
}

button {
  height: 50px;
}

.signup-buttons {
  button {
    margin: 0 20px !important;
    width: 150px !important;
  }
  .back {
    background-color: white !important;
    background: rgb(158, 124, 124) !important;
  }
}

label {
  color: $body-color !important;
  line-height: .5 !important;
  font-size: small !important;
  margin-bottom: 5px;
}

input, select {
  height: 45px !important;
  border: 1px solid #323334 !important;
  border-radius: 3px;
  text-align: left;
  color: $body-color !important;
  padding: 10px;
}

[type="checkbox"] {
  height: initial !important;
  > label {
    line-height: 1.75 !important;
  }
}

.recurly-hosted-field {
  height: 45px;
  padding: 10px;
  background-color: white !important;
  border: 1px solid #323334;
  border-radius: 3px;
  font-size: 14px;
}

.form-group {
  margin-top: 0px !important;
  margin-bottom: 15px !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: rgba(0, 0, 0, .1) !important;
}

.w-50 {
  width: 49% !important;
}

.form-input-password-show-toggle {
  color: $body-color;
}

.total {
  text-align: left;
  padding: 20px !important;
  background-color: $aira-color-notice-bg !important;
  border-top: 1px solid $aira-color-notice-border-secondary !important;
  border-bottom: 1px solid $aira-color-notice-border-secondary !important;
  font-size: large;
  font-weight: normal;
}

.signup-order-summary-section, #agreement-clause {
  padding: 0 20px !important;
  background-color: $body-bg !important;
}

.signup-order-summary-item-title {
  font-size: 16px;
}

.form-section-title {
  // margin-bottom: 10px !important;
  font-size: larger;
  font-weight: bold;
}

.signup-summary-info {
  > div {
    margin-bottom: 0px;
  }
  .signup-summary-info-button {
    clear: both;
    float: left;
  }
}

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  140 !default;
