// resets margins and paddings for headers, ul, ol and p
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
body,
label,
blockquote, .container-fluid {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

// Set Image and Objects to have a max-width of 100%
embed,
iframe,
img,
object,
video {
  max-width: 100%;
}